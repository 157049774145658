import React from "react"
import RacepageTdF from "../components/racepageOneDay"

const Race2021 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Il Lombardia",
      edition: 2021,
      what: "Race data",
      updated: "2021-10-08 16:48:13",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21798: {
        teamId: "21798",
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00ebn Team",
        teamNationCode: "FRA",
      },
      21799: {
        teamId: "21799",
        teamUciCode: "APT",
        teamName: "Astana-Premier Tech",
        teamNationCode: "KAZ",
      },
      21800: {
        teamId: "21800",
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
      },
      21805: {
        teamId: "21805",
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
      },
      21807: {
        teamId: "21807",
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
      },
      21808: {
        teamId: "21808",
        teamUciCode: "DQT",
        teamName: "Deceuninck-Quick Step",
        teamNationCode: "BEL",
      },
      21811: {
        teamId: "21811",
        teamUciCode: "EFN",
        teamName: "EF Education-NIPPO",
        teamNationCode: "USA",
      },
      21813: {
        teamId: "21813",
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
      },
      21817: {
        teamId: "21817",
        teamUciCode: "ISN",
        teamName: "Israel Start-Up Nation",
        teamNationCode: "ISR",
      },
      21819: {
        teamId: "21819",
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
      },
      21821: {
        teamId: "21821",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange",
        teamNationCode: "AUS",
      },
      21822: {
        teamId: "21822",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      21824: {
        teamId: "21824",
        teamUciCode: "TQA",
        teamName: "Team Qhubeka NextHash",
        teamNationCode: "RSA",
      },
      21826: {
        teamId: "21826",
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
      },
      21829: {
        teamId: "21829",
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
      },
      21832: {
        teamId: "21832",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "GER",
      },
      21836: {
        teamId: "21836",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      21838: {
        teamId: "21838",
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
      },
      21844: {
        teamId: "21844",
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
      },
      21846: {
        teamId: "21846",
        teamUciCode: "ANS",
        teamName: "Androni Giocattoli-Sidermec",
        teamNationCode: "ITA",
      },
      21851: {
        teamId: "21851",
        teamUciCode: "BCF",
        teamName: "Bardiani CSF Faizan\u00e8",
        teamNationCode: "ITA",
      },
      21855: {
        teamId: "21855",
        teamUciCode: "IWG",
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        teamNationCode: "BEL",
      },
      21864: {
        teamId: "21864",
        teamUciCode: "ARK",
        teamName: "Team Ark\u00e9a-Samsic",
        teamNationCode: "FRA",
      },
      21869: {
        teamId: "21869",
        teamUciCode: "THR",
        teamName: "Vini Zab\u00f9",
        teamNationCode: "ITA",
      },
      22761: {
        teamId: "22761",
        teamUciCode: "EOK",
        teamName: "EOLO-KOMETA Cycling Team",
        teamNationCode: "ITA",
      },
    },
    riders: {
      1580: {
        id: 1580,
        startno: 45,
        firstName: "Fabio",
        lastName: "Felline",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-03-29",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      16576: {
        id: 16576,
        startno: 196,
        firstName: "Nick",
        lastName: "Schultz",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-09-13",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      20147: {
        id: 20147,
        startno: 121,
        firstName: "Adam",
        lastName: "Yates",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1992-08-07",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      46163: {
        id: 46163,
        startno: 233,
        firstName: "Marc",
        lastName: "Hirschi",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-08-24",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      3224: {
        id: 3224,
        startno: 71,
        firstName: "Cesare",
        lastName: "Benedetti",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1987-08-03",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      27265: {
        id: 27265,
        startno: 61,
        firstName: "Giovanni",
        lastName: "Carboni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-08-31",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
        stillInTheRace: "Y",
      },
      45602: {
        id: 45602,
        startno: 77,
        firstName: "Ide",
        lastName: "Schelling",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-02-06",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      17252: {
        id: 17252,
        startno: 137,
        firstName: "Odd Christian",
        lastName: "Eiking",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-12-28",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      32493: {
        id: 32493,
        startno: 224,
        firstName: "Amanuel",
        lastName: "Gebreigzabhier",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1994-08-17",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      39009: {
        id: 39009,
        startno: 154,
        firstName: "Chris",
        lastName: "Harper",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-11-23",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      34011: {
        id: 34011,
        startno: 11,
        firstName: "Beno\u00eet",
        lastName: "Cosnefroy",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-10-17",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      27101: {
        id: 27101,
        startno: 143,
        firstName: "Omer",
        lastName: "Goldstein",
        nationCode: "ISR",
        nationName: "Israel",
        birthDate: "1996-08-13",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      117: {
        id: 117,
        startno: 91,
        firstName: "Rigoberto",
        lastName: "Uran",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1987-01-26",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      78129: {
        id: 78129,
        startno: 202,
        firstName: "Thymen",
        lastName: "Arensman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-12-04",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      70377: {
        id: 70377,
        startno: 166,
        firstName: "Maxim",
        lastName: "Van Gils",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-11-25",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      828: {
        id: 828,
        startno: 125,
        firstName: "Ben",
        lastName: "Swift",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1987-11-05",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      94645: {
        id: 94645,
        startno: 25,
        firstName: "Ben",
        lastName: "Tulett",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2001-08-26",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      16988: {
        id: 16988,
        startno: 156,
        firstName: "Sam",
        lastName: "Oomen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-08-15",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      50047: {
        id: 50047,
        startno: 103,
        firstName: "Davide",
        lastName: "Bais",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-04-02",
        teamId: 22761,
        teamName: "EOLO-KOMETA Cycling Team",
        stillInTheRace: "Y",
      },
      452: {
        id: 452,
        startno: 211,
        firstName: "Domenico",
        lastName: "Pozzovivo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1982-11-30",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
      },
      84019: {
        id: 84019,
        startno: 6,
        firstName: "Remco",
        lastName: "Evenepoel",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-01-25",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      33341: {
        id: 33341,
        startno: 36,
        firstName: "Filippo",
        lastName: "Tagliani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-08-14",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
        stillInTheRace: "Y",
      },
      68868: {
        id: 68868,
        startno: 32,
        firstName: "Alexander",
        lastName: "Cepeda",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1998-06-16",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
        stillInTheRace: "Y",
      },
      7771: {
        id: 7771,
        startno: 186,
        firstName: "Lukasz",
        lastName: "Owsian",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1990-02-24",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      352: {
        id: 352,
        startno: 114,
        firstName: "Matthieu",
        lastName: "Ladagnous",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1984-12-12",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      22007: {
        id: 22007,
        startno: 247,
        firstName: "Riccardo",
        lastName: "Stacchiotti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-11-08",
        teamId: 21869,
        teamName: "Vini Zab\u00f9",
        stillInTheRace: "Y",
      },
      37351: {
        id: 37351,
        startno: 102,
        firstName: "Vincenzo",
        lastName: "Albanese",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-11-12",
        teamId: 22761,
        teamName: "EOLO-KOMETA Cycling Team",
        stillInTheRace: "Y",
      },
      2278: {
        id: 2278,
        startno: 53,
        firstName: "Mikel",
        lastName: "Landa",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-12-13",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      9344: {
        id: 9344,
        startno: 73,
        firstName: "Emanuel",
        lastName: "Buchmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1992-11-18",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      16639: {
        id: 16639,
        startno: 124,
        firstName: "Tao",
        lastName: "Geoghegan Hart",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-03-30",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      794: {
        id: 794,
        startno: 237,
        firstName: "Diego",
        lastName: "Ulissi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-07-15",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      14126: {
        id: 14126,
        startno: 177,
        firstName: "Davide",
        lastName: "Villella",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-06-27",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      20576: {
        id: 20576,
        startno: 65,
        firstName: "Andrea",
        lastName: "Garosio",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-06-12",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
        stillInTheRace: "Y",
      },
      10807: {
        id: 10807,
        startno: 84,
        firstName: "Ruben",
        lastName: "Fernandez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1991-03-01",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      20378: {
        id: 20378,
        startno: 134,
        firstName: "Simone",
        lastName: "Petilli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-04",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      27332: {
        id: 27332,
        startno: 162,
        firstName: "Steff",
        lastName: "Cras",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-02-13",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      16687: {
        id: 16687,
        startno: 54,
        firstName: "Matej",
        lastName: "Mohoric",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1994-10-19",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      22679: {
        id: 22679,
        startno: 22,
        firstName: "Floris",
        lastName: "De Tier",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-01-20",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      18655: {
        id: 18655,
        startno: 151,
        firstName: "Primoz",
        lastName: "Roglic",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1989-10-29",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      58639: {
        id: 58639,
        startno: 95,
        firstName: "Sergio",
        lastName: "Higuita",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-08-01",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      42185: {
        id: 42185,
        startno: 205,
        firstName: "Chris",
        lastName: "Hamilton",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1995-05-18",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      12474: {
        id: 12474,
        startno: 1,
        firstName: "Julian",
        lastName: "Alaphilippe",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-06-11",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      25680: {
        id: 25680,
        startno: 187,
        firstName: "Dayer",
        lastName: "Quintana",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1992-08-10",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      40238: {
        id: 40238,
        startno: 31,
        firstName: "Mattia",
        lastName: "Bais",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-10-19",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
        stillInTheRace: "Y",
      },
      116: {
        id: 116,
        startno: 175,
        firstName: "Jos\u00e9 Joaquin",
        lastName: "Rojas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1985-06-08",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      580: {
        id: 580,
        startno: 106,
        firstName: "Francesco",
        lastName: "Gavazzi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1984-08-01",
        teamId: 22761,
        teamName: "EOLO-KOMETA Cycling Team",
        stillInTheRace: "Y",
      },
      45419: {
        id: 45419,
        startno: 214,
        firstName: "Andreas",
        lastName: "Stokbro",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-04-08",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
      },
      14123: {
        id: 14123,
        startno: 17,
        firstName: "Lawrence",
        lastName: "Warbasse",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1990-06-28",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      2294: {
        id: 2294,
        startno: 145,
        firstName: "Reto",
        lastName: "Hollenstein",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1985-08-22",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      29855: {
        id: 29855,
        startno: 44,
        firstName: "Rodrigo",
        lastName: "Contreras",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-06-02",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      868: {
        id: 868,
        startno: 195,
        firstName: "Mikel",
        lastName: "Nieve",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1984-05-26",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      69890: {
        id: 69890,
        startno: 117,
        firstName: "Attila",
        lastName: "Valter",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "1998-06-12",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      11011: {
        id: 11011,
        startno: 232,
        firstName: "Davide",
        lastName: "Formolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-10-25",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      65515: {
        id: 65515,
        startno: 192,
        firstName: "Kevin",
        lastName: "Colleoni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-11-11",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      37376: {
        id: 37376,
        startno: 57,
        firstName: "Stephen",
        lastName: "Williams",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1996-06-09",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      6245: {
        id: 6245,
        startno: 76,
        firstName: "Patrick",
        lastName: "Konrad",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1991-10-13",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      602: {
        id: 602,
        startno: 131,
        firstName: "Jan",
        lastName: "Bakelants",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1986-02-14",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      16795: {
        id: 16795,
        startno: 223,
        firstName: "Niklas",
        lastName: "Eg",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-01-06",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      28738: {
        id: 28738,
        startno: 244,
        firstName: "Jakub",
        lastName: "Mareczko",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-04-30",
        teamId: 21869,
        teamName: "Vini Zab\u00f9",
        stillInTheRace: "Y",
      },
      713: {
        id: 713,
        startno: 142,
        firstName: "Chris",
        lastName: "Froome",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1985-05-20",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      27303: {
        id: 27303,
        startno: 87,
        firstName: "R\u00e9my",
        lastName: "Rochas",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-05-18",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      2275: {
        id: 2275,
        startno: 201,
        firstName: "Romain",
        lastName: "Bardet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-11-09",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      2281: {
        id: 2281,
        startno: 165,
        firstName: "Tomasz",
        lastName: "Marczynski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1984-03-06",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      71208: {
        id: 71208,
        startno: 83,
        firstName: "Thomas",
        lastName: "Champion",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-09-08",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      51244: {
        id: 51244,
        startno: 14,
        firstName: "Cl\u00e9ment",
        lastName: "Champoussin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-05-29",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      10862: {
        id: 10862,
        startno: 24,
        firstName: "Kristian",
        lastName: "Sbaragli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-05-08",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      730: {
        id: 730,
        startno: 155,
        firstName: "Steven",
        lastName: "Kruijswijk",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-06-07",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      27271: {
        id: 27271,
        startno: 101,
        firstName: "Lorenzo",
        lastName: "Fortunato",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-05-09",
        teamId: 22761,
        teamName: "EOLO-KOMETA Cycling Team",
        stillInTheRace: "Y",
      },
      19274: {
        id: 19274,
        startno: 213,
        firstName: "Victor",
        lastName: "Campenaerts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-28",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
      },
      752: {
        id: 752,
        startno: 5,
        firstName: "Dries",
        lastName: "Devenyns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1983-07-22",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      14658: {
        id: 14658,
        startno: 217,
        firstName: "Karel",
        lastName: "Vacek",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "2000-09-09",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
      },
      9451: {
        id: 9451,
        startno: 35,
        firstName: "Eduardo",
        lastName: "Sepulveda",
        nationCode: "ARG",
        nationName: "Argentina",
        birthDate: "1991-06-13",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
        stillInTheRace: "Y",
      },
      1181: {
        id: 1181,
        startno: 185,
        firstName: "Romain",
        lastName: "Hardy",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-08-24",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      37378: {
        id: 37378,
        startno: 113,
        firstName: "David",
        lastName: "Gaudu",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-10-10",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      28057: {
        id: 28057,
        startno: 246,
        firstName: "Daniel",
        lastName: "Pearson",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1994-02-26",
        teamId: 21869,
        teamName: "Vini Zab\u00f9",
        stillInTheRace: "Y",
      },
      3165: {
        id: 3165,
        startno: 7,
        firstName: "Pieter",
        lastName: "Serry",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-11-21",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      40783: {
        id: 40783,
        startno: 47,
        firstName: "Matteo",
        lastName: "Sobrero",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-05-14",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      66495: {
        id: 66495,
        startno: 72,
        firstName: "Giovanni",
        lastName: "Aleotti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-05-25",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      27283: {
        id: 27283,
        startno: 123,
        firstName: "Edward",
        lastName: "Dunbar",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1996-09-01",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      48539: {
        id: 48539,
        startno: 235,
        firstName: "Brandon",
        lastName: "McNulty",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1998-04-02",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      1008: {
        id: 1008,
        startno: 173,
        firstName: "Nelson",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1989-03-06",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      20466: {
        id: 20466,
        startno: 64,
        firstName: "Davide",
        lastName: "Gabburo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-04-01",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
        stillInTheRace: "Y",
      },
      6257: {
        id: 6257,
        startno: 82,
        firstName: "Natnael",
        lastName: "Berhane",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1991-01-05",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      6415: {
        id: 6415,
        startno: 133,
        firstName: "Louis",
        lastName: "Meintjes",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1992-02-21",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      3930: {
        id: 3930,
        startno: 226,
        firstName: "Toms",
        lastName: "Skujins",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1991-06-15",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      33402: {
        id: 33402,
        startno: 63,
        firstName: "Filippo",
        lastName: "Fiorelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-11-19",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
        stillInTheRace: "Y",
      },
      27301: {
        id: 27301,
        startno: 16,
        firstName: "Aur\u00e9lien",
        lastName: "Paret-Peintre",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-02-27",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      16569: {
        id: 16569,
        startno: 147,
        firstName: "Michael",
        lastName: "Woods",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1986-10-12",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      78384: {
        id: 78384,
        startno: 93,
        firstName: "Diego",
        lastName: "Camargo",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1998-05-03",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      69145: {
        id: 69145,
        startno: 204,
        firstName: "Mark",
        lastName: "Donovan",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-04-03",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      6228: {
        id: 6228,
        startno: 161,
        firstName: "Tim",
        lastName: "Wellens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-05-10",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      6496: {
        id: 6496,
        startno: 183,
        firstName: "Winner",
        lastName: "Anacona",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1988-08-11",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      8078: {
        id: 8078,
        startno: 27,
        firstName: "Louis",
        lastName: "Vervaeke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-10-06",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      10996: {
        id: 10996,
        startno: 174,
        firstName: "Antonio",
        lastName: "Pedrero",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1991-10-23",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      83214: {
        id: 83214,
        startno: 105,
        firstName: "Erik",
        lastName: "Fetter",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "2000-04-05",
        teamId: 22761,
        teamName: "EOLO-KOMETA Cycling Team",
        stillInTheRace: "Y",
      },
      41208: {
        id: 41208,
        startno: 216,
        firstName: "Dylan",
        lastName: "Sunderland",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-02-26",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
      },
      6538: {
        id: 6538,
        startno: 236,
        firstName: "Jan",
        lastName: "Polanc",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1992-05-06",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      108732: {
        id: 108732,
        startno: 94,
        firstName: "Daniel",
        lastName: "Arroyave",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "2000-06-19",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      48461: {
        id: 48461,
        startno: 42,
        firstName: "Samuele",
        lastName: "Battistella",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-11-14",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      6410: {
        id: 6410,
        startno: 193,
        firstName: "Damien",
        lastName: "Howson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1992-08-13",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      353: {
        id: 353,
        startno: 116,
        firstName: "Anthony",
        lastName: "Roux",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1987-04-18",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      45992: {
        id: 45992,
        startno: 231,
        firstName: "Tadej",
        lastName: "Pogacar",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1998-09-21",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      1715: {
        id: 1715,
        startno: 194,
        firstName: "Christopher",
        lastName: "Juul-Jensen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1989-07-06",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      8068: {
        id: 8068,
        startno: 51,
        firstName: "Dylan",
        lastName: "Teuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-03-01",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      9302: {
        id: 9302,
        startno: 75,
        firstName: "Felix",
        lastName: "Gro\u00dfschartner",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1993-12-23",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      37280: {
        id: 37280,
        startno: 127,
        firstName: "Pavel",
        lastName: "Sivakov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1997-07-11",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      2741: {
        id: 2741,
        startno: 222,
        firstName: "Gianluca",
        lastName: "Brambilla",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-08-22",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      38004: {
        id: 38004,
        startno: 242,
        firstName: "Simone",
        lastName: "Bevilacqua",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-02-22",
        teamId: 21869,
        teamName: "Vini Zab\u00f9",
        stillInTheRace: "Y",
      },
      51: {
        id: 51,
        startno: 141,
        firstName: "Daniel",
        lastName: "Martin",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1986-08-20",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      38937: {
        id: 38937,
        startno: 86,
        firstName: "Victor",
        lastName: "Lafay",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-01-17",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      346: {
        id: 346,
        startno: 136,
        firstName: "Rein",
        lastName: "Taaram\u00e4e",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1987-04-24",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      53723: {
        id: 53723,
        startno: 164,
        firstName: "Andreas",
        lastName: "Kron",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-06-01",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      55807: {
        id: 55807,
        startno: 56,
        firstName: "Hermann",
        lastName: "Pernsteiner",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1990-08-07",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      57961: {
        id: 57961,
        startno: 12,
        firstName: "Clement",
        lastName: "Berthet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-08-02",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      12482: {
        id: 12482,
        startno: 21,
        firstName: "Xandro",
        lastName: "Meurisse",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-01-31",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      18564: {
        id: 18564,
        startno: 153,
        firstName: "Koen",
        lastName: "Bouwman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-12-02",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      82146: {
        id: 82146,
        startno: 97,
        firstName: "Jimmy",
        lastName: "Whelan",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-07-11",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      52660: {
        id: 52660,
        startno: 212,
        firstName: "Sean",
        lastName: "Bennett",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1996-03-31",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
      },
      65240: {
        id: 65240,
        startno: 3,
        firstName: "Andrea",
        lastName: "Bagioli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-03-23",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      26520: {
        id: 26520,
        startno: 112,
        firstName: "Matteo",
        lastName: "Badilatti",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1992-07-30",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      33285: {
        id: 33285,
        startno: 34,
        firstName: "Simone",
        lastName: "Ravanelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-07-04",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
        stillInTheRace: "Y",
      },
      16635: {
        id: 16635,
        startno: 184,
        firstName: "Elie",
        lastName: "Gesbert",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-07-01",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      860: {
        id: 860,
        startno: 111,
        firstName: "Thibaut",
        lastName: "Pinot",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-05-29",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      11086: {
        id: 11086,
        startno: 245,
        firstName: "Davide",
        lastName: "Orrico",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-02-17",
        teamId: 21869,
        teamName: "Vini Zab\u00f9",
        stillInTheRace: "Y",
      },
      14791: {
        id: 14791,
        startno: 4,
        firstName: "Fausto",
        lastName: "Masnada",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-11-06",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      25690: {
        id: 25690,
        startno: 176,
        firstName: "Gonzalo",
        lastName: "Serrano",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-08-17",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      6230: {
        id: 6230,
        startno: 46,
        firstName: "Alexey",
        lastName: "Lutsenko",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1992-09-07",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      737: {
        id: 737,
        startno: 197,
        firstName: "Andrey",
        lastName: "Zeits",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1986-12-14",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      871: {
        id: 871,
        startno: 122,
        firstName: "Jonathan",
        lastName: "Castroviejo",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1987-04-27",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      3991: {
        id: 3991,
        startno: 234,
        firstName: "Rafal",
        lastName: "Majka",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1989-09-12",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      38: {
        id: 38,
        startno: 171,
        firstName: "Alejandro",
        lastName: "Valverde",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1980-04-25",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      30020: {
        id: 30020,
        startno: 62,
        firstName: "Luca",
        lastName: "Covili",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-02-10",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
        stillInTheRace: "Y",
      },
      10990: {
        id: 10990,
        startno: 81,
        firstName: "Guillaume",
        lastName: "Martin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-06-09",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      6269: {
        id: 6269,
        startno: 132,
        firstName: "Jan",
        lastName: "Hirt",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1991-01-22",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      581: {
        id: 581,
        startno: 225,
        firstName: "Bauke",
        lastName: "Mollema",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1986-11-26",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      37757: {
        id: 37757,
        startno: 67,
        firstName: "Daniel",
        lastName: "Savini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-09-26",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
        stillInTheRace: "Y",
      },
      37761: {
        id: 37761,
        startno: 15,
        firstName: "Jaakko",
        lastName: "H\u00e4nninen",
        nationCode: "FIN",
        nationName: "Finland",
        birthDate: "1997-04-16",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      705: {
        id: 705,
        startno: 144,
        firstName: "Ben",
        lastName: "Hermans",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1986-08-06",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      27190: {
        id: 27190,
        startno: 92,
        firstName: "William",
        lastName: "Barta",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1996-01-04",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      16786: {
        id: 16786,
        startno: 203,
        firstName: "Tiesj",
        lastName: "Benoot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-03-11",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      39387: {
        id: 39387,
        startno: 167,
        firstName: "Harm",
        lastName: "Vanhoucke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-06-17",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      161: {
        id: 161,
        startno: 182,
        firstName: "Maxime",
        lastName: "Bouet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1986-11-03",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      6266: {
        id: 6266,
        startno: 26,
        firstName: "Petr",
        lastName: "Vakoc",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1992-07-11",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      38195: {
        id: 38195,
        startno: 157,
        firstName: "Jonas",
        lastName: "Vingegaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1996-12-10",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      6241: {
        id: 6241,
        startno: 104,
        firstName: "Mark",
        lastName: "Christian",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1990-11-20",
        teamId: 22761,
        teamName: "EOLO-KOMETA Cycling Team",
        stillInTheRace: "Y",
      },
      69502: {
        id: 69502,
        startno: 215,
        firstName: "Mauro",
        lastName: "Schmid",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1999-12-04",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
      },
      37447: {
        id: 37447,
        startno: 207,
        firstName: "Michael",
        lastName: "Storer",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1997-02-28",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      48505: {
        id: 48505,
        startno: 37,
        firstName: "Nicola",
        lastName: "Venchiarutti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-10-07",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
        stillInTheRace: "Y",
      },
      37281: {
        id: 37281,
        startno: 41,
        firstName: "Aleksandr",
        lastName: "Vlasov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1996-04-23",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      6242: {
        id: 6242,
        startno: 191,
        firstName: "Simon",
        lastName: "Yates",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1992-08-07",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      6184: {
        id: 6184,
        startno: 115,
        firstName: "Sebastien",
        lastName: "Reichenbach",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1989-05-28",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      14128: {
        id: 14128,
        startno: 241,
        firstName: "Edoardo",
        lastName: "Zardini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-11-02",
        teamId: 21869,
        teamName: "Vini Zab\u00f9",
        stillInTheRace: "Y",
      },
      3096: {
        id: 3096,
        startno: 43,
        firstName: "Manuele",
        lastName: "Boaro",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-12-03",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      81835: {
        id: 81835,
        startno: 52,
        firstName: "Santiago",
        lastName: "Buitrago",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1999-09-26",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      27268: {
        id: 27268,
        startno: 74,
        firstName: "Matteo",
        lastName: "Fabbro",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-04-10",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      28781: {
        id: 28781,
        startno: 126,
        firstName: "Gianni",
        lastName: "Moscon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-04-20",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      133: {
        id: 133,
        startno: 221,
        firstName: "Vincenzo",
        lastName: "Nibali",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1984-11-14",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      532: {
        id: 532,
        startno: 172,
        firstName: "Dario",
        lastName: "Cataldo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1985-03-17",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      45988: {
        id: 45988,
        startno: 66,
        firstName: "Alessandro",
        lastName: "Monaco",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-02-04",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
        stillInTheRace: "Y",
      },
      168: {
        id: 168,
        startno: 85,
        firstName: "Simon",
        lastName: "Geschke",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1986-03-13",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      27275: {
        id: 27275,
        startno: 135,
        firstName: "Lorenzo",
        lastName: "Rota",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-05-23",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      7998: {
        id: 7998,
        startno: 163,
        firstName: "Matthew",
        lastName: "Holmes",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-12-08",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      27210: {
        id: 27210,
        startno: 55,
        firstName: "Domen",
        lastName: "Novak",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1995-07-24",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      6229: {
        id: 6229,
        startno: 23,
        firstName: "Jimmy",
        lastName: "Janssens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-05-30",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      3352: {
        id: 3352,
        startno: 152,
        firstName: "George",
        lastName: "Bennett",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1990-04-07",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      37494: {
        id: 37494,
        startno: 96,
        firstName: "Neilson",
        lastName: "Powless",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1996-09-03",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      66973: {
        id: 66973,
        startno: 206,
        firstName: "Andreas",
        lastName: "Leknessund",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1999-05-21",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      50303: {
        id: 50303,
        startno: 2,
        firstName: "Joao",
        lastName: "Almeida",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1998-08-05",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      96981: {
        id: 96981,
        startno: 227,
        firstName: "Antonio",
        lastName: "Tiberi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-06-24",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      46841: {
        id: 46841,
        startno: 33,
        firstName: "Daniel",
        lastName: "Mu\u00f1oz",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1996-11-21",
        teamId: 21846,
        teamName: "Androni Giocattoli-Sidermec",
        stillInTheRace: "Y",
      },
      2276: {
        id: 2276,
        startno: 181,
        firstName: "Nairo",
        lastName: "Quintana",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1990-02-04",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
        stillInTheRace: "Y",
      },
      20579: {
        id: 20579,
        startno: 107,
        firstName: "Edward",
        lastName: "Ravasi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-06-05",
        teamId: 22761,
        teamName: "EOLO-KOMETA Cycling Team",
        stillInTheRace: "Y",
      },
      1860: {
        id: 1860,
        startno: 243,
        firstName: "Marco",
        lastName: "Frapporti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1985-03-30",
        teamId: 21869,
        teamName: "Vini Zab\u00f9",
        stillInTheRace: "Y",
      },
      17215: {
        id: 17215,
        startno: 13,
        firstName: "Geoffrey",
        lastName: "Bouchard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-04-01",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      16620: {
        id: 16620,
        startno: 146,
        firstName: "James",
        lastName: "Piccoli",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1991-09-05",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Il Lombardia"
  const raceID = 24

  return (
    <RacepageTdF
      title={`${race} 2021`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2021
